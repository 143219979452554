import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import dynamic from 'next/dynamic'
import { ElementType, FC, useEffect, useMemo } from 'react'

import Fallback from '@/components/Fallback'
import Login2FAForm from '@/components/Forms/Login2FAForm'
import LoginForm from '@/components/Forms/LoginForm'

import { useUi } from '@/bus/ui'
import { useUser } from '@/bus/user'
import { routes } from '@/config'
import { DoublePanel } from '@/layouts'

export const Login: FC = () => {
  const {
    t,
    i18n: { language }
  } = useTranslation()
  const { isAuthorized, is2FA } = useUser()
  const { setSettings } = useUi()

  if (isAuthorized) {
    location.href = routes.SERVICES.replace(':lang', language)
  }

  const RandomBenefitsCard = useMemo(
    () =>
      dynamic(() => import(`@/components/BenefitsCard/RandomBenefitsCard`), {
        ssr: false,
        loading: () => <Fallback />
      }) as ElementType,
    []
  )

  useEffect(() => {
    setSettings({ login: true, menu: false, footer: false })
    return () => {
      setSettings({ login: false })
    }
  }, [])

  return (
    <>
      <NextSeo title={t('auth:signin.login.head.title') as string} />

      <DoublePanel side={<RandomBenefitsCard />}>
        {is2FA ? <Login2FAForm /> : <LoginForm />}
      </DoublePanel>
    </>
  )
}
